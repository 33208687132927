function Home() {

  return (
    <div>
      <p> 'this is the home' </p>
    </div>
  );

}

export default Home;