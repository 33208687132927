import {useEffect, useState} from "react";
import axios from 'axios';
import TextField from '@mui/material/TextField';

const baseUrl = process.env.REACT_APP_BASE_URL;
const hostedUI = process.env.REACT_APP_COGNITO_HOSTED_UI;


function Mapping() {

  const [map, setMap] = useState('');
  const [user, setUser] = useState(false);
  const [hostedUi] = useState(hostedUI);

  let getMappings = async () => {

    try {
      let resp = await axios.get(`${baseUrl}/auth-validation`, { withCredentials: true });
      let auth = resp.data;

      if(auth != null){
        setUser(true);
        console.log('Logged in!');
      }
      console.log('AUTHENTICATED',auth);

    } catch(e){
      console.log(e);
    }

    try {
      const columnMapUrl = 'https://integrations-api.dev.newbreedhub.com/import-file-column-mappings?integrations_id=tdc-a0b25798-d77d-459b-ba6d-e45acf4ee90d';

      const resp = await axios.get(columnMapUrl);
      console.log(resp.data);

      setMap(JSON.stringify(resp.data));

    } catch(e) {
      console.log(e);
    }

  }

  useEffect( () => {
    getMappings();
  }, []);

  let clickTest = () => {
    setMap('hello!');
  };

  const handleInputChange = (e) => {
    setMap(e.target.value);
  };

  return (
    <div>
      this is the mapping
      <p><button onClick={ clickTest }>Button 1</button></p>
      <p><button onClick={ getMappings }>Button 2</button></p>

      { user ? (
        <div>You are logged in</div>
      ) : (
        <div><a href={ hostedUi }>login</a></div>
      )}


      <TextField
        // id="filled-multiline-static"
        label="Mappings"
        multiline
        fullWidth
        className="full-width-input"
        value={ map || '' }
        onChange={ handleInputChange }
        variant="filled"
      />

    </div>
  );

}

export default Mapping;