import { Route, Routes } from "react-router-dom";
import './App.css';
import Home from './Home.js';
import Mapping from './settings/Mapping.js';

const AppRoutes = () => (
  <Routes>
    <Route index element={<Home />}/>
    <Route path='/mapping' element={<Mapping />}/>
  </Routes>
);

function App() {
  return (
    <AppRoutes />
  );
};

export default App;
